.navbar {
    background-color:#dddddd;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
   
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
  }
  
  .nav-logo {
    color: black;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 5rem;
    flex-grow: 1;
    margin-top: -15px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }
  
  .nav-links {
    color: black;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .fa-code {
    margin-left: 1rem;
  }
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: #F8D057;
  }
  
  .nav-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 50%;
      border-top: 1px solid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: rgb(213, 213, 206);
      left: 0px;
      opacity: 1;
      /* transition: all 0.5s ease; */
      z-index: 1;
      margin-left: 50%;
    }
    .nav-item .active {
      color: white;
      border: none;
    }
    .nav-links {
      /* padding: 1.5rem; */
      width: 50%;
      display: table;
      margin-left: 25%;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: black;
    }
  }